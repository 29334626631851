.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
	-ms-word-break: inherit;
	word-break: inherit;
}

.ant-layout-sider-children,
.ant-menu {
	color: #000000;
	background: #eee; /* #2d3741 */
}

.ant-menu-submenu > .ant-menu {
	background: #eee;
}

/* .ant-layout-sider-children * {
	background: #2d3741;
} */

.ant-layout-content {
	flex-shrink: 0;
}

.button-icon {
	padding: 0 5px;
}

.button-icon img {
	width: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.ant-table-row--summary td {
	background: #fafafa;
	border-top: 3px solid #ccc;
}

.ant-layout-header .anticon {
	font-size: 16px;
}

.ant-layout-header .ant-select-arrow-icon {
	font-size: inherit;
}

:root .ant-table-column-sorter .anticon-caret-up,
:root .ant-table-column-sorter .anticon-caret-down {
	font-size: 18px;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
	width: 20px;
}

.ant-modal-content {
	height: 100%;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
	line-height: 8px;
	height: 8px;
}

.ant-table-column-sorter-up:after,
.ant-table-column-sorter-down:after {
	width: 20px;
}

.text-center {
	text-align: center;
}

.ant-popover-inner-content {
	zoom: 1.2;
}

.ant-notification {
	zoom: 1.2;
}

.ant-btn-success {
	color: #fff;
	background-color: #28a745;
	border-color: #28a745;
}

.ant-btn-success:hover {
	color: #fff;
	background-color: #218838;
	border-color: #1e7e34;
}

.ant-btn-success.focus {
	box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.ant-btn-danger {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
}

/*.ant-table-tbody > tr > td {*/
/*  padding: 5px 8px !important;*/
/*}*/

.ant-btn-danger:hover {
	color: #fff;
	background-color: #c82333;
	border-color: #bd2130;
}

.ant-btn-danger.focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.ant-btn-warning {
	color: #fff;
	background-color: #ffc107;
	border-color: #ffc107;
}

.ant-btn-warning:hover {
	color: #fff;
	background-color: #e0a800;
	border-color: #d39e00;
}

.ant-btn-secondary {
	color: #fff;
	background-color: #d9d9d9;
	border-color: #cdcdcd;
}

.ant-btn-secondary:hover {
	color: #fff;
	background-color: #cdcdcd;
	border-color: #cdcdcd;
}

.ant-btn-secondary:focus {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.1);
	border-color: rgba(0, 0, 0, 0.1);
}

.ant-btn-warning.focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.tree-custom .ant-tree-node-content-wrapper {
	height: auto !important;
	width: calc(100% - 24px) !important;
	display: inline-block !important;
	vertical-align: middle;
}

.find-in {
	color: #1e7e34;
	font-weight: 700;
}

.find-in--theme-product {
	color: black;
	background-color: #FFDD00;
	font-weight: 700;
}

.tree-custom .ant-tree-title {
	width: 100% !important;
	display: block !important;
}

.tree-custom .content {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.ant-tree li .ant-tree-node-content-wrapper {
	cursor: unset;
}

.ant-tree li:hover > .ant-tree-node-content-wrapper {
	background-color: transparent !important;
}

.ant-tree li {
	/* padding: 8px !important; */
	margin: 0 -8px;
}

.ant-table-thead > tr.ant-table-row-hover > td,
.ant-table-tbody > tr.ant-table-row-hover > td,
.ant-table-thead > tr:hover > td,
.ant-table-tbody > tr:hover > td {
	background-color: rgba(0, 0, 0, 0.08);
}

.ant-tree li:hover,
.ant-tree li span.ant-tree-switcher:not(.ant-tree-switcher-noop):hover,
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
	background-color: rgba(0, 0, 0, 0.04);
}

#editable input {
	border: none;
	outline: none;
	border-radius: 0;
}

#editable input:focus {
	border: none;
	outline: none;
}

#progressbar {
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	height: 6px;
	background-color: #297336;
	z-index: 1000;
	overflow: hidden;
	transform: translateY(-100%);
	transition: transform 0.3s;
	will-change: transform;
}

#progressbar.is-active {
	transform: translateY(0);
}

#progressbar::before {
	content: "";
	position: absolute;
	top: 0;
	margin: auto;
	bottom: 0;
	display: block;
	width: 300px;
	height: 3px;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 0 0 1px 1px #fff;
	transform: translateX(-50%);
	animation: slideLR 1s alternate-reverse infinite;
	will-change: left;
}

@keyframes slideLR {
	0% {
		left: 0;
	}
	100% {
		left: 100%;
	}
}

._bg-gray {
	background: #fafafa;
}

.f-danger {
	color: red;
	/* font-weight: 600; */
}

.f-success {
	color: #0b8b0b;
	/* font-weight: 600; */
}

.f-info {
	color: rgb(255, 165, 0);
}

.p-r_15 {
	padding-right: 15px;
}

.p-15 {
	padding: 15px;
}

.m-t_15 {
	margin-top: 15px;
}

.s-r_15 {
	padding-right: 15px;
	margin-right: 15px;
}

.m-b_15 {
	margin-bottom: 15px;
}

.w-50 {
	width: 50%;
}

.w-33 {
	width: 33.3%;
}

._w-33 {
	width: calc(33.3% - 15px);
}

._w-100 {
	width: calc(100% - 15px);
}

.w-100 {
	width: 100%;
}

.input_number_without_slide .ant-input-number-handler-wrap {
	display: none;
}

/*
.ant-table-thead th {
    overflow: hidden !important;
    position: relative !important;
}

.ant-table-column-sort {
    position: relative !important;
}

.ant-table-column-sorter {
    position: static !important;
}

.ant-table-column-sorter-up, .ant-table-column-sorter-down {
    position: static;
}

.ant-table-column-sorter-up:after, .ant-table-column-sorter-down:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: auto !important;
    height: auto !important;
}

.ant-table-column-sorter-down.on:after,
.ant-table-column-sorter-up.on:after {
    z-index: 95;
}

.ant-table-column-sorter-up.off:after {
    z-index: 100;
}

.ant-table-column-sorter-down.off:after {
    z-index: 105;
}

.ant-table-filter-icon {
    z-index: 110 !important;
    position: relative !important;
}
*/

/* a {
    color: #000;
}

a:hover {
    color: rgb(45, 125, 47);
} */

.flex-between {
	display: flex;
	justify-content: space-between;
}

.flex-wrap {
	flex-wrap: wrap;
}

._pb-def {
	padding-bottom: 1.25rem;
}

.error-title {
	font-size: 80px;
	font-weight: 900;
	font-family: Arial, sans-serif;
	line-height: 1;
	color: #f5222e;
	margin: 40px 0 30px;
}

.m-r_15 {
	margin-right: 15px;
}

.m-l_15 {
	margin-left: 15px;
}

/** Antd restyle */

/* .ant-table-tbody > tr:hover > td {
    background: #f3efb3;
} */

/* .ant-btn:hover, .ant-btn:focus {
    color: #42804b;
    background-color: #fff;
    border-color: #42804b;
} */

.ant-menu-item:hover {
	color: #000000;
	background-color: rgba(0, 0, 0, 0.15);
}

.ant-menu-submenu-title:hover {
	background-color: rgba(0, 0, 0, 0.15);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: rgba(0, 0, 0, 0.15);
}

.ant-layout-sider-trigger {
	background-color: #42804b;
}

/* hover */

.simple-link {
	color: inherit;
}

.ant-breadcrumb a,
.simple-link {
	border-bottom: 1px dashed transparent;
	transition: color 0.2s ease, border-color 0.2s ease;
}

.ant-breadcrumb a:hover,
.simple-link:hover {
	color: #42804b;
	border-bottom-color: #42804b;
}

/* pagination */

.ant-pagination-item {
	cursor: pointer;
}

.ant-pagination-item-active {
	cursor: default;
}

.ant-pagination-jump-next {
	border: 1px solid transparent;
	border-radius: 4px;
}

.ant-pagination-jump-next:hover,
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active):hover {
	border-color: #ccc;
}

/* btn */

.ant-btn--multiple-row {
	height: auto;
	line-height: 20px;
	min-height: 32px;
	padding-top: 6px;
	padding-bottom: 6px;
	white-space: normal;
}

/* row */

.ant-row--size-compact {
	max-width: 900px;
	margin-right: auto;
	margin-left: 0;
}

.ant-row--size-tiny {
	max-width: 450px;
	margin-right: auto;
	margin-left: 0;
}

._min-width-small {
	min-width: 136px;
	flex-shrink: 0;
}

._min-width-full {
	min-width: 100%;
	flex-shrink: 0;
}

.separator {
	border-width: 0;
	border-bottom: 1px solid;
	margin: 10px 0;
}

.clarification {
	font-size: 80%;
	line-height: 1.4rem;
	margin: -0.4rem 0 0.4rem;
}

@media only screen and (max-width: 991px) {
	.ant-row--size-tiny\:def {
		max-width: 450px;
		margin-right: auto;
		margin-left: 0;
	}

	.md-w-100 {
		width: 100%;
	}

	.ant-layout {
		overflow: auto;
	}
}

.ant-dropdown-link:hover {
	color: #297336;
}

.nl-p10 > span {
	display: block;
	padding: 5px 0;
}

.profile .thumbnail {
	position: relative;
	z-index: 1;
	background: #fff;
}

.thumbnail {
	max-width: 100%;
	width: 200px;
	height: 200px;
	object-fit: cover;
}

.thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.profile-thumbnail {
	padding: 0 !important;
	margin-right: 20px;
}

.profile .ant-divider.ant-divider-horizontal {
	display: block;
	clear: none;
}

/* .list_card:hover .list_card-titled {
	display: none;
} */

/* .form-skeleton .ant-col {
  height: 90px;
} */

.editable .ant-table-thead > tr > th .ant-table-header-column {
	width: 100px;
}

.editable--shrinked {
	width: max-content;
	max-width: 100%;
}

.editable .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
	position: relative;
}

.editable .ant-form-explain {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	text-align: center !important;
}

.ant-card-body {
	/* padding: 15px; */
}

.pic {
	mask-image: url("/public/images/pic.svg");
}

.ant-card-body .ant-divider {
	margin: 8px 0;
}

.list_card__value {
	word-break: break-all;
	line-height: 1.3em;
	max-height: 2.6em;
	overflow: hidden;
}

.list_card__title {
	line-height: 1.3em;
	max-height: 2.6em;
}

.editable-input {
	width: 100% !important;
	text-align: right;
}

.editable-input.highlight-valid {
	border-bottom: 2px solid #42804b;
}

.editable .ant-form-explain {
	text-align: right;
	padding-right: 10px;
}

.editable-input input {
	text-align: right;
	width: 100%;
}

.editable-input .ant-input-number-handler-wrap {
	display: none;
}

.editable-input__suffix--percent {
	position: absolute;
	right: -15px;
	top: -10px;
}

.profile .ant-divider.ant-divider-horizontal {
	margin: 13px 0;
}

@media screen and (min-width: 990px) {

	.boed .ant-row-flex .ant-col:nth-child(5) {
		width: 18%;
	}

	.boed .ant-row-flex .ant-col:nth-child(6) {
		width: 19%;
	}

}


.ant-table--fix-width .ant-table-fixed {
	width: auto !important;
	max-width: 100%;
}

.ant-tabs {
	overflow: visible !important;
}

@-webkit-keyframes loader {
	0% {
		width: 0;
	}
	20% {
		width: 10%;
	}
	25% {
		width: 24%;
	}
	43% {
		width: 41%;
	}
	56% {
		width: 50%;
	}
	66% {
		width: 52%;
	}
	71% {
		width: 60%;
	}
	75% {
		width: 76%;
	}
	94% {
		width: 86%;
	}
	100% {
		width: 100%;
	}
}

@keyframes loader {
	0% {
		width: 0;
	}
	20% {
		width: 10%;
	}
	25% {
		width: 24%;
	}
	43% {
		width: 41%;
	}
	56% {
		width: 50%;
	}
	66% {
		width: 52%;
	}
	71% {
		width: 60%;
	}
	75% {
		width: 76%;
	}
	94% {
		width: 86%;
	}
	100% {
		width: 100%;
	}
}

.progress-bar {
	border-radius: 60px;
	overflow: hidden;
	width: 100%;
}

.progress-bar span {
	display: block;
}

.bar {
	background: rgba(0, 0, 0, 0.075);
}

.progress {
	-webkit-animation: loader 8s ease infinite;
	animation: loader 8s ease infinite;
	background: #75b800;
	color: #fff;
	padding: 5px;
	width: 0;
}

.progress-bar {
	left: 50%;
	max-width: 50%;
	position: absolute;
	top: 50%;
	-webkit-transform: translate3d(-50%, -50%, 0);
	transform: translate3d(-50%, -50%, 0);
}

.page-error {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
}

.page-error--logged-out {
	position: initial;
}

.page-error--logged-out .page-error__button {
	height: 35px;
	font-size: 16px;
	padding: 0 24px;
	border-radius: 3px;
}

.page-error__content {
	display: flex;
	flex-direction: column;
	align-items: safe center;
}

.page-error__logo {
	margin-bottom: 30px;
}

.page-error__content .page-error__title {
	position: initial;
	padding-bottom: 40px;
	transform: none;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 500;
	color: #000;
}

.page-error__content .page-error__button {
	position: initial;
	transform: none;
}

.page-error__title {
	position: absolute;
	left: 50%;
	top: 30px;
	transform: translateX(-50%);
	max-width: 872px;
	text-transform: uppercase;
	font-size: 18px;
	text-align: center;
	color: #fff;
}

.page-error__button {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 2vh;
	text-transform: uppercase;
	height: 55px;
	font-size: 20px;
	padding: 0 32px;
	border-radius: 10px;
}

@media only screen and (min-width: 800px) {
	.page-error__title {
		top: 12vh;
		font-size: 25px;
	}
}

@media only screen and (min-width: 1756px) {
	.page-error__title {
		font-size: 43px;
	}

	.page-error__title--not-found {
		top: 100px;
		bottom: initial;
	}

	.page-error__button {
		font-size: 1.8rem;
	}
}

.editable * {
	text-align: center !important;
}

.custom-disabled-field {
	pointer-events: none;
}

.styled-table .ant-table-tbody > tr > td {
	background-color: #eee;
}

.styled-table .ant-table-fixed {
	width: 100% !important;
}

.styled-table .ant-table-bordered .ant-table-thead > tr > th {
	border-color: #fff;
	background-color: #42804b;
	color: #fff;
}

.styled-table .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
	background-color: #42804b;
}

.styled-table .ant-table-expanded-row .ant-table-tbody > tr > td,
.styled-table .ant-table-expanded-row td {
	background-color: #fff !important;
}

.styled-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
	background-color: #ffffff;
}

.styled-table tr.ant-table-expanded-row {
	background-color: #ffffff;
}

.row--archived {
	color: #d2d2d2;
}

.nested-table-lvl-2 .ant-table-expanded-row .ant-table-tbody > tr > td, .styled-table .ant-table-expanded-row td:nth-child(2) {
	padding: 11px 7px;
}

.styled-table .has-no-subitems .ant-table-row-expand-icon {
	display: none;
}

.error-above .has-error .ant-form-explain,
.error-above .has-error .ant-form-split {
	color: #42804b;
}

.error-above .has-error .ant-input-number,
.error-above .has-error .ant-time-picker-input {
	border-color: #42804b;
}

.error-above .ant-input-number {
	box-shadow: none !important;
}

.error-above .has-error .ant-input-number:not([disabled]):hover {
	border-color: #42804b;
}

.editable .custom-disabled-field .editable-input {
	background-color: transparent;
}

.aligned-form .ant-form-item-label {
	white-space: initial;
	text-align: left;
}

tr td {
	backface-visibility: hidden;
}

.history-table th, .history-table td, .history-table input {
	text-align: center !important;
}

.history-table .field {
	height: 40px;
	margin: 0 0 10px;
	display: inline-flex;
	align-items: center;
}

.history-table .ant-input-number-disabled, .history-table .ant-input-number-disabled .ant-input-number-input {
	cursor: default;
}

.history-table .ant-table-scroll, .history-table .ant-table-body {
	overflow-x: auto !important;
}

.history-table .ant-table-tbody > tr > td {
	word-break: break-word;
}

.custom-google-map {
	height: 400px;
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	user-select: none;
	margin-top: 8px;
}

@media only screen and (min-width: 992px) {
	.custom-google-map {
		min-height: 500px;
		height: 90vh;
		margin-top: 24px;
	}
}

.custom-google-map__button {
	padding: 5px 25px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

	position: absolute !important;
	bottom: 15px;
	right: 15px;
	cursor: pointer;
	transition: 0.3s;
}

.custom-google-map__label {
	text-shadow: 1px 1px 2px #fff;
}

.custom-google-map__charts {
	margin-top: 24px;
}

.custom-google-map__charts svg:not(:root) {
	overflow: visible;
}

.table-auto-scroll .ant-table-scroll, .table-auto-scroll .ant-table-body {
	overflow-x: auto !important;
}

.actual-sales-plans-table tr.has-no-child .ant-table-row-expand-icon,
.actual-sales-plans-table.cloned-row .ant-table-row-expand-icon {
	display: none;
}

.actual-sales-plans-table tr.is-dragging td {
	background: #a8b3a8;
}

.actual-sales-plans-table tr.is-over td {
	background: #e8e8e8 !important;
}

.actual-sales-plans-table .ant-table-thead > tr,
.actual-sales-plans-table .ant-table-tbody > tr {
	transition: none;
}

.actual-sales-plans-table tr.ant-table-expanded-row table {
	border: 0;
}

.actual-sales-plans-table tr.ant-table-expanded-row {
	background: #fff !important;
}

.actual-sales-plans-table tr.ant-table-expanded-row > td:first-child {
	background: #fff;
	border-color: #cdcdcd;
}

.actual-sales-plans-table tr.ant-table-expanded-row .ant-table-tbody tr {
	background: #e7e7e7;
}

.actual-sales-plans-table tr.ant-table-expanded-row .ant-table-tbody td,
.actual-sales-plans-table tr.ant-table-expanded-row .ant-table-thead th {
	border-color: #cdcdcd !important;
}

.number-range-divider {
	line-height: 2.5;
	margin: 0 10px;
}

@media only screen and (min-width: 992px) {
	.product-filter-row > .ant-col {
		width: 20%;
	}

	.product-filter-row > .ant-col:nth-child(2) .ant-form-item-label,
	.product-filter-row > .ant-col:nth-child(7) .ant-form-item-label {
		margin-bottom: 1px;
	}
}

.actual-sales-plan-draft-table .ant-table-bordered .ant-table-body > table,
.actual-sales-plan-draft-table .ant-table-bordered .ant-table-body tr > td,
.actual-sales-plan-draft-table .ant-table-bordered .ant-table-body tr > th {
	border-color: #cdcdcd;
}

.actual-sales-plan-draft-table .product-desc-status--is-valid .model-desc,
.actual-sales-plan-draft-table .service-code-status--is-valid .service-code {
	background-color: #28a745 !important;
}

.actual-sales-plan-draft-table .product-desc-status--is-invalid .model-desc,
.actual-sales-plan-draft-table .service-code-status--is-invalid .service-code {
	background-color: red !important;
}

.actual-sales-plan-draft-table tr.is-trashed td  {
	border-color: #cdcdcd;
	background-color: #e7e7e7;
}

.actual-sales-plan-draft-table tr.is-trashed td.model-desc,
.actual-sales-plan-draft-table tr.is-trashed td.service-code {
	border-color: #cdcdcd !important;
	background-color: #e7e7e7 !important;
}

.actual-sales-plan-draft-table tr.is-trashed:hover td,
.actual-sales-plan-draft-table tr.is-trashed:hover td.model-desc,
.actual-sales-plan-draft-table tr.is-trashed:hover td.service-code {
	background-color: #a8b3a8 !important;
}

.ant-table-spin-holder .ant-spin-dot {
	font-size: 32px;
}

.ant-table-spin-holder .ant-spin-dot-item {
	width: 14px;
	height: 14px;
}

.ant-table-fixed-header .ant-table-header .ant-table-fixed {
	border-bottom: 1px solid #e8e8e8 !important;
}

.actual-sales-plans-summary-table td.is-hovered {
	background-color: #a8b3a8 !important;
}

.actual-sales-plans-summary-table .cell-content-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 8px;
}

.actual-sales-plans-summary-table .cell-content-wrapper span {
	position: sticky;
	top: 50%;
	transform: translateY(-50%);
}

.dashboard-tables-row > .ant-col {
	width: 100%;
}

.ant-table-row-cell-break-word .interactive-table-cell {
	padding: 12px 8px;
	margin: -12px -8px;
	cursor: pointer;
}

.ant-table-row-cell-break-word .interactive-table-cell .simple-link {
	border-bottom-color: inherit;
}

.ant-table-row-cell-break-word .interactive-table-cell:hover .simple-link {
	color: #42804b;
	border-bottom-color: #42804b;
}

.recharts-tooltip {
	padding: 10px;
	border: 1px solid #eee;
	background-color: #fff;
	color: #000;
}

.sales-efficiency-modal {
	width: 100% !important;
	max-width: 1360px;
}

@media only screen and (min-width: 1200px) {
	.dashboard-tables-row > .ant-col:nth-child(1) {
		width: 44%;
	}

	.dashboard-tables-row > .ant-col:nth-child(2) {
		width: 56%;
	}
}

@media only screen and (min-width: 1440px) {
	.dashboard-tables-row > .ant-col:nth-child(1) {
		width: 41.6%;
	}

	.dashboard-tables-row > .ant-col:nth-child(2) {
		width: 58.4%;
	}
}

@media (hover: none) {
	.ant-table-hide-scrollbar {
		margin-bottom: 0 !important;
	}

	.ant-table-fixed-header .ant-table-header .ant-table-fixed {
		border-bottom: 0 !important;
	}
}
